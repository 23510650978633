import React from 'react'
import PropTypes from 'prop-types'
import './TextSection.scss'

const TextSection = ({ contentTitle, contentText }) => (
  <div className="textSection__content">
    {contentTitle && (
      <h3 className="textSection__title" dangerouslySetInnerHTML={{ __html: contentTitle }} />
    )}
    {contentText && (
      <span className="textSection__text" dangerouslySetInnerHTML={{ __html: contentText }} />
    )}
  </div>
)

TextSection.propTypes = {
  contentTitle: PropTypes.string,
  contentText: PropTypes.string,
}

TextSection.defaultProps = {
  contentTitle: '',
  contentText: '',
}

export default TextSection
