import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import TextSection from '../components/_shared/TextSection/TextSection'
import ImageSection from '../components/_shared/ImageSection/ImageSection'
import InNumbersSection from '../components/_shared/InNumbersSection/InNumbersSection'
import ContactFormSection from '../components/_shared/ContactFormSection/ContactFormSection'

const AboutUsPage = () => {
  const data = useStaticQuery(graphql`{
  placeholderImage: file(relativePath: {eq: "about-us-image.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <Layout pageTitle="O nas" headerImage={1}>
      <div className="container container--page">
        <TextSection
          contentText="Zapewnianie bezpieczeństwa i komfortu pracy to nasze główne cele. Zajmujemy się kompleksową dystrybucją środków ochrony indywidualnej za pośrednictwem automatów wydających, dzięki czemu możemy zapewnić twoim pracownikom dostęp do niezbędnego asortymentu 24 godziny na dobę, 7 dni w tygodniu.
        <br/>
        <br/>Współpracujemy wyłącznie z najlepszymi producentami artykułów z zakresu BHP, w trosce o zadowolenie naszych klientów. Wysoka jakość produktów i usług przekłada się na rosnące zadowolenie naszych kontrahentów."
        />
        <ImageSection
          alt="O NAS"
          sectionClass="sectionImage--content"
          imageFluid={data.placeholderImage.childImageSharp.gatsbyImageData}
        />
        <TextSection
          contentTitle="Współpraca z Lipus Safety zagwarantuje Ci"
          contentText="&middot; Najwyższej jakości produkty chroniące życie i zdrowie twoich pracowników,
        <br/>&middot; Profesjonalne doradztwo, które zwiększy bezpieczeństwo w twojej firmie,
        <br/>&middot; Urządzenia vendingowe na sprzedaż i wynajem,
        <br/>&middot; Nowoczesne rozwiązania wewnątrzzakładowe, które zmniejszą koszty twojego przedsiębiorstwa."
        />
        <TextSection
          contentTitle="Szeroki wachlarz usług"
          contentText="Lipus Safety to nie tylko vending BHP. W naszej ofercie znajdziesz również outsourcing BHP, magazyny konsygnacyjne, serwisowanie odzieży roboczej, czy profesjonalne czyszczenie rękawic. Zajmujemy się również własną produkcją odzieży ochronno-roboczej i marketingowej. Z chęcią udzielimy fachowej porady z zakresu BHP, aby bezpieczeństwo pracy w Twoim przedsiębiorstwie stało na jak najwyższym poziomie. Stale udoskonalamy naszą ofertę, aby móc odpowiadać na zróżnicowane potrzeby klientów."
        />
        <TextSection
          contentTitle="Gwarantujemy najwyższą jakość"
          contentText="Lipus Safety zapewni bezpieczeństwo i wygodę pracy na każdym stanowisku Twojej firmy. Zajmujemy się obsługą przedsiębiorstw z rozmaitych branż, w tym budownictwa, farmacji, automotive, AGD, przemysłu ciężkiego i innych. Kompleksową troskę o zdrowie i życie Twoich pracowników połączymy ze skutecznym obniżaniem kosztów przedsiębiorstwa i usprawnianiem jego działań, dzięki czemu praca stanie się jeszcze większą przyjemnością. Jesteśmy tu dla Ciebie  - z największą przyjemnością podejmiemy się współpracy z Twoją firmą. Zapraszamy do zapoznania się z naszą ofertą!"
        />
        <InNumbersSection />
      </div>
      <ContactFormSection />
    </Layout>
  );
}

export default AboutUsPage
