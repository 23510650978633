import React from 'react'
import './InNumbersSection.scss'
import CountUp from 'react-countup'
import { InView } from 'react-intersection-observer'

const numbers = [
  {
    number: 3,
    postfixNumber: '',
    text: 'Obsługiwanych krajów',
  },
  {
    number: 100,
    postfixNumber: '+',
    text: 'Zadowolonych klientów',
  },
  {
    number: 15,
    postfixNumber: '',
    text: 'Sprawdzonych dostawców',
  },
  {
    number: 1000,
    postfixNumber: '+',
    text: 'Dostępnych produktów',
  },
]

const InNumbersSection = () => {
  const getElement = (element, index) => (
    <div key={index} className="inNumbersSection__element">
      <CountUp start={0} end={element.number} duration={Math.floor(55 / element.number)}>
        {({ countUpRef, start }) => (
          <InView triggerOnce onChange={start}>
            <span className="inNumbersSection__number" ref={countUpRef} />
            <span className="inNumbersSection__number">{element.postfixNumber}</span>
          </InView>
        )}
      </CountUp>
      <span className="inNumbersSection__text">{element.text}</span>
    </div>
  )

  return <div className="inNumbersSection">{numbers.map(getElement)}</div>
}

export default InNumbersSection
